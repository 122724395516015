.date-picker-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.date-picker-section .info {
  margin-right: 30px;
}

.date-picker-section .info .title {
  font-weight: 600;
  font-size: 30px;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  font-weight: 700;
  color: #22282c;
  text-transform: capitalize;
  margin-bottom: 5px;
  line-height: 1.2;
}

.date-picker-section .info .desc {
	font-size: 14px;
  line-height: 16px;
  color: #848484;
}

.date-picker-section .info .desc span {
	color: #2271b1;
}

.range-date-picker .btn {
  background: #444444;
  border-radius: 5px;
  white-space: nowrap;
}

.range-date-picker .btn:hover {
  background: darken($color: #444444, $amount: 10%);
}

.date-picker-wrapper {
  width: 100%;
  max-width: 524px;
}

.range-date-picker {
  display: flex;
}

.range-date-picker .react-daterange-picker {
  width: 100%;
  margin-right: 4px;
}

.range-date-picker .react-daterange-picker__clear-button {
  display: none;
}

.range-date-picker .react-daterange-picker__wrapper {
  border-color: #448aff;
  border-radius: 5px;
  padding-left: 20px;
  background: #fff;
}

.range-date-picker
  .react-daterange-picker--open
  .react-daterange-picker__wrapper {
  border-radius: 5px 5px 0 0;
}

.range-date-picker .react-daterange-picker__button {
  background: #448aff url("../../images/arrow-down-white.svg") center no-repeat;
  background-size: 12px;
  height: 53px;
  width: 53px;
  border-radius: 0 2px 2px 0;
}

.range-date-picker .react-daterange-picker__button svg {
  display: none;
}

.range-date-picker .react-daterange-picker__range-divider {
  display: none;
}

.range-date-picker .react-daterange-picker__inputGroup {
  padding: 0 20px 0 40px;
  position: relative;
}

.range-date-picker .react-daterange-picker__inputGroup:first-child {
  padding-left: 55px;
}

.range-date-picker .react-daterange-picker__inputGroup:first-child::after {
  content: "From:";
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  transform: translateY(-50%);
  color: #22282c;
  font-weight: 700;
}

.range-date-picker
  .react-daterange-picker__inputGroup:not(:first-child)::after {
  content: "To:";
  position: absolute;
  left: 0;
  top: calc(50% - 0px);
  transform: translateY(-50%);
  color: #22282c;
  font-weight: 700;
}

.range-date-picker .react-daterange-picker__inputGroup input {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #22282c;
  outline: none;
}

.range-date-picker .react-daterange-picker__calendar {
  width: 100%;
}

.range-date-picker .react-daterange-picker__calendar .react-calendar {
  width: 100%;
  border-color: #448aff;
  border-radius: 0 0 5px 5px;
  border-top: none;
}

@media (max-width: 767px) {
  .date-picker-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-picker-section .info {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
	flex-direction: column;
  }

  .date-picker-wrapper {
    width: 100%;
    margin-top: 20px;
    max-width: 100%;
  }
}

@media (max-width: 567px) {
  .range-date-picker .react-daterange-picker__button {
    height: 40px;
  }

  .range-date-picker .btn {
    padding: 10px 15px;
  }

  .range-date-picker .react-daterange-picker__wrapper {
    padding-left: 10px;
  }

  .range-date-picker .react-daterange-picker__inputGroup:first-child::after,
  .range-date-picker
    .react-daterange-picker__inputGroup:not(:first-child)::after {
    font-size: 14px;
  }

  .range-date-picker .react-daterange-picker__inputGroup:first-child {
    padding-left: 43px;
  }

  .range-date-picker .react-daterange-picker__inputGroup {
    padding: 0 10px 0 25px;
  }

  .range-date-picker .react-daterange-picker__button {
    width: 40px;
  }
}

@media (max-width: 500px) {
  .range-date-picker {
    flex-direction: column;
  }

  .range-date-picker .react-daterange-picker {
    margin-right: 0;
  }

  .range-date-picker .btn {
    margin-top: 10px;
  }

  .range-date-picker .react-daterange-picker__inputGroup {
    min-width: 0;
  }
}
