.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.profile-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-form {
  max-width: 520px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.auth-form .brand-logo {
  margin-bottom: 43px;
  height: 50px;
  max-width: 300px;
}

.auth-form .brand-logo img {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.userAvatar {
  display: flex;
  justify-content: center;
}

.userAvatar img{
  border-radius: 50%;
  height: 80px;
  width: 80px;
  object-fit: cover;
  box-shadow: 0 0 0 5px rgb(0, 0, 0, 0.1);
}

.auth-form form {
  background: #ffffff;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.1);
  padding: 70px;
  width: 100%;
  margin-top: 45px;
}

.auth-form form input:disabled{
  opacity: 0.7;
}

.profile-form form{
  box-shadow: none;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 10px;
}

.auth-form form button[type="submit"] {
  width: 100%;
}

.auth-form .divider {
  display: flex;
  align-items: center;
  color: #868686;
}

.auth-form .divider::after {
  content: "";
  display: block;
  flex: 1;
  background-color: #e4e4e4;
  height: 1px;
  margin-left: 10px;
}

.auth-form .divider::before {
  content: "";
  display: block;
  flex: 1;
  background-color: #e4e4e4;
  height: 1px;
  margin-right: 10px;
}

.auth-form .auth-bottom {
  margin-top: 30px;
  text-align: center;
}

.auth-form.register-form {
  max-width: 550px;
}

.auth-form.register-form form {
  padding: 50px;
}

.notification-box {
  padding: 10px 15px;
  color: #383d41;
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  border-radius: 5px;
}

.notification-box.success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

.notification-box.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.select-account-wrapper{
  max-width: 500px;
  margin: auto;
}

.select-account-wrapper form{
  border: 1px solid rgba(0, 0, 0, 0.103);
  border-radius: 10px;
  padding: 30px;
}

.select-account-wrapper form .btn{
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
}

@media (max-width: 767px) {
  .auth-form form,
  .auth-form.register-form form {
    padding: 50px 40px;
    margin-top: 30px;
  }

  .auth-form .brand-logo {
    margin-bottom: 20px;
    max-width: 200px;
  }
}

@media (max-width: 440px) {
  .auth-form form,
  .auth-form.register-form form {
    padding: 30px 20px;
  }
}
