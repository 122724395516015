.graphics-wrapper .visitors-table {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1px;
  margin-top: 15px;
}

.graphics-wrapper .map-stats {
  padding: 30px;
}

.graphics-wrapper .visitors-table .table-wrapper {
  width: 50%;
  box-shadow: none;
}

.graphics-wrapper .visitors-table th,
.graphics-wrapper .visitors-table td {
  padding-left: 30px;
  padding-right: 30px;
}

.graphics-wrapper .visitors-table th:last-child,
.graphics-wrapper .visitors-table td:last-child {
  width: 20%;
  text-align: center;
}

.chart-container {
  width: 100%;
  display: flex; /* Ensures the chart stretches fully within its parent */
  justify-content: center; /* Optional: Centers the chart if needed */
}

.graphics-wrapper .visitors-table .table-wrapper:nth-child(even) {
  border-left: 0;
}
.comparison-filter-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.comparison-summary{
	font-size: 50px;
    font-weight: 500;
    color: #444;
	line-height: 1.2;
}
.comparison-summary small{
	font-size: 30px;
    font-weight: 400;
    color: #444;
}
.comparison-summary .vs{
	font-size: 22px;
    font-weight: 400;
    padding: 0 10px;
    color: #444;
}
.country {
  display: inline-block;
  padding-left: 41px;
  text-transform: capitalize;
  position: relative;
}
.country::before{
	width: 24px;
	height: 15px;
	content: '';
	position: absolute;
	top: 2px;
	left: 0;
	background: url("../../images/flag_sprite.png") top left no-repeat;
}

.analytify_afghanistan:before{background-position: -176px -136px}
.analytify_angola:before{background-position: -176px -151px}
.analytify_albania:before{background-position: -201px -91px}
.analytify_united_arab_emirates:before{background-position: -301px -91px}
.analytify_argentina:before{background-position: -251px -1px}
.analytify_armenia:before{background-position: -151px -91px}
.analytify_french_southern_and_antarctic_lands:before{background-position: -326px -196px}
.analytify_australia:before{background-position: -251px -16px}
.analytify_austria:before{background-position: -126px -91px}
.analytify_azerbaijan:before{background-position: -101px -91px}
.analytify_burundi:before{background-position: -76px -151px}
.analytify_belgium:before{background-position: -51px -91px}
.analytify_benin:before{background-position: -151px -151px}
.analytify_burkina_faso:before{background-position: -101px -151px}
.analytify_bangladesh:before{background-position: -126px -136px}
.analytify_bulgaria:before{background-position: -1px -91px}
.analytify_bahamas:before{background-position: -76px -46px}
.analytify_bosnia_and_herzegovina:before{background-position: -26px -91px}
.analytify_belarus:before{background-position: -76px -91px}
.analytify_belize:before{background-position: -26px -46px}
.analytify_bermuda:before{background-position: -226px -196px}
.analytify_bolivia:before{background-position: -226px -1px}
.analytify_brazil:before{background-position: -201px -1px}
.analytify_brunei:before{background-position: -76px -136px}
.analytify_bhutan:before{background-position: -101px -136px}
.analytify_botswana:before{background-position: -126px -151px}
.analytify_central_african_republic:before{background-position: -1px -151px}
.analytify_canada:before{background-position: -1px -46px}
.analytify_switzerland:before{background-position: -201px -46px}
.analytify_chile:before{background-position: -176px -1px}
.analytify_china:before{background-position: -26px -136px}
.analytify_ivory_coast,.analytify_cote_d_ivoire:before{background-position: -101px -196px}
.analytify_cameroon:before{background-position: -51px -151px}
.analytify_democratic_republic_of_the_congo:before{background-position: -251px -31px}
.analytify_republic_of_the_congo:before{background-position: -276px -136px}
.analytify_colombia:before{background-position: -151px -1px}
.analytify_costa_rica:before{background-position: -326px -31px}
.analytify_cuba:before{background-position: -301px -31px}
.analytify_northern_cyprus:before{background-position: -251px -196px}
.analytify_cyprus:before{background-position: -301px -76px}
.analytify_czech_republic:before{background-position: -276px -76px}
.analytify_germany:before{background-position: -151px -76px}
.analytify_djibouti:before{background-position: -251px -136px}
.analytify_denmark:before{background-position: -251px -76px}
.analytify_dominican_republic:before{background-position: -251px -31px}
.analytify_algeria:before{background-position: -201px -151px}
.analytify_ecuador:before{background-position: -76px -1px}
.analytify_egypt:before{background-position: -201px -136px}
.analytify_eritrea:before{background-position: -76px -196px}
.analytify_spain:before{background-position: -251px -46px}
.analytify_estonia:before{background-position: -151px -211px}
.analytify_ethiopia:before{background-position: -51px -196px}
.analytify_finland:before{background-position: -226px -76px}
.analytify_fiji:before{background-position: -226px -16px}
.analytify_falkland_islands:before{background-position: -1px -211px}
.analytify_france,.analytify_reunion,.analytify_martinique,.analytify_mayotte:before{background-position: -201px -76px}
.analytify_gabon:before{background-position: -26px -196px}
.analytify_united_kingdom:before{background-position: -151px -46px}
.analytify_georgia:before{background-position: -176px -76px}
.analytify_ghana:before{background-position: -326px -181px}
.analytify_guinea:before{background-position: -276px -181px}
.analytify_gambia:before{background-position: -1px -196px}
.analytify_guinea_bissau:before{background-position: -301px -181px}
.analytify_equatorial_guinea:before{background-position: -226px -136px}
.analytify_greece:before{background-position: -126px -76px}
.analytify_greenland:before{background-position: -101px -211px}
.analytify_guatemala:before{background-position: -176px -31px}
.analytify_french_guiana:before{background-position: -126px -211px}
.analytify_guyana:before{background-position: -126px -1px}
.analytify_honduras:before{background-position: -126px -31px}
.analytify_croatia:before{background-position: -326px -76px}
.analytify_haiti:before{background-position: -326px -61px}
.analytify_hungary:before{background-position: -101px -76px}
.analytify_indonesia:before{background-position: -76px -61px}
.analytify_india:before{background-position: -326px -121px}
.analytify_ireland:before{background-position: -51px -76px}
.analytify_iran:before{background-position: -276px -121px}
.analytify_iraq:before{background-position: -251px -121px}
.analytify_iceland:before{background-position: -76px -76px}
.analytify_israel:before{background-position: -176px -211px}
.analytify_italy:before{background-position: -26px -76px}
.analytify_jamaica:before{background-position: -101px -31px}
.analytify_jordan:before{background-position: -201px -121px}
.analytify_japan:before{background-position: -226px -121px}
.analytify_kazakhstan:before{background-position: -176px -121px}
.analytify_kenya:before{background-position: -251px -181px}
.analytify_kyrgyzstan:before{background-position: -51px -211px}
.analytify_cambodia:before{background-position: -51px -136px}
.analytify_south_korea:before{background-position: -126px -121px}
.analytify_kosovo:before{background-position: -301px -196px}
.analytify_kuwait:before{background-position: -101px -121px}
.analytify_laos:before{background-position: -51px -121px}
.analytify_lebanon:before{background-position: -26px -121px}
.analytify_liberia:before{background-position: -201px -181px}
.analytify_libya:before{background-position: -176px -181px}
.analytify_sri_lanka:before{background-position: -101px -106px}
.analytify_lesotho:before{background-position: -226px -181px}
.analytify_lithuania:before{background-position: -301px -61px}
.analytify_luxembourg:before{background-position: -276px -61px}
.analytify_latvia:before{background-position: -1px -76px}
.analytify_morocco:before{background-position: -26px -181px}
.analytify_moldova:before{background-position: -201px -61px}
.analytify_madagascar:before{background-position: -151px -181px}
.analytify_mexico:before{background-position: -76px -31px}
.analytify_macedonia:before{background-position: -251px -61px}
.analytify_mali:before{background-position: -101px -181px}
.analytify_myanmar:before{background-position: -26px -16px}
.analytify_montenegro:before{background-position: -151px -61px}
.analytify_mongolia:before{background-position: -301px -106px}
.analytify_mozambique:before{background-position: -1px -181px}
.analytify_mauritania:before{background-position: -76px -181px}
.analytify_malawi:before{background-position: -126px -181px}
.analytify_malaysia:before{background-position: -1px -121px}
.analytify_namibia:before{background-position: -326px -166px}
.analytify_new_caledonia:before{background-position: -276px -196px}
.analytify_niger:before{background-position: -301px -166px}
.analytify_nigeria:before{background-position: -276px -166px}
.analytify_nicaragua:before{background-position: -51px -31px}
.analytify_netherlands:before{background-position: -276px -61px}
.analytify_norway:before{background-position: -101px -61px}
.analytify_nepal:before{background-position: -126px -196px}
.analytify_new_zealand:before{background-position: -101px -16px}
.analytify_oman:before{background-position: -276px -106px}
.analytify_pakistan:before{background-position: -251px -106px}
.analytify_panama:before{background-position: -201px -196px}
.analytify_peru:before{background-position: -26px -1px}
.analytify_philippines:before{background-position: -226px -106px}
.analytify_papua_new_guinea:before{background-position: -51px -16px}
.analytify_poland:before{background-position: -76px -61px}
.analytify_puerto_rico:before{background-position: -176px -196px}
.analytify_north_korea:before{background-position: -151px -121px}
.analytify_portugal:before{background-position: -76px -211px}
.analytify_paraguay:before{background-position: -226px -211px}
.analytify_qatar:before{background-position: -201px -106px}
.analytify_romania:before{background-position: -26px -61px}
.analytify_russia:before{background-position: -176px -106px}
.analytify_rwanda:before{background-position: -251px -166px}
.analytify_western_sahara:before{background-position: -201px -121px}
.analytify_saudi_arabia:before{background-position: -151px -106px}
.analytify_sudan:before{background-position: -51px -166px}
.analytify_south_sudan:before{background-position: -76px -166px}
.analytify_senegal:before{background-position: -51px -151px}
.analytify_solomon_islands:before{background-position: -1px -16px}
.analytify_sierra_leone:before{background-position: -151px -166px}
.analytify_el_salvador:before{background-position: -51px -31px}
.analytify_somaliland:before{background-position: -251px -211px}
.analytify_somalia:before{background-position: -126px -166px}
.analytify_republic_of_serbia:before{background-position: -326px -46px}
.analytify_suriname:before{background-position: -151px -196px}
.analytify_slovakia:before{background-position: -301px -46px}
.analytify_slovenia:before{background-position: -276px -46px}
.analytify_sweden:before{background-position: -226px -46px}
.analytify_swaziland:before{background-position: -26px -166px}
.analytify_syria:before{background-position: -76px -106px}
.analytify_chad:before{background-position: -26px -61px}
.analytify_togo:before{background-position: -326px -151px}
.analytify_thailand:before{background-position: -26px -106px}
.analytify_tajikistan:before{background-position: -51px -106px}
.analytify_turkmenistan:before{background-position: -26px -211px}
.analytify_east_timor,.analytify_timor_leste:before{background-position: -1px -136px}
.analytify_trinidad_and_tobago:before{background-position: -301px -16px}
.analytify_tunisia:before{background-position: -301px -151px}
.analytify_turkey:before{background-position: -1px -106px}
.analytify_united_republic_of_tanzania:before{background-position: -1px -166px}
.analytify_uganda:before{background-position: -276px -151px}
.analytify_ukraine:before{background-position: -176px -46px}
.analytify_uruguay:before{background-position: -51px -256px}
.analytify_united_states:before{background-position: -276px -16px}
.analytify_uzbekistan:before{background-position: -276px -91px}
.analytify_venezuela:before{background-position: -76px -1px}
.analytify_vietnam:before{background-position: -251px -91px}
.analytify_vanuatu:before{background-position: -276px -1px}
.analytify_west_bank:before{background-position: -201px -121px}
.analytify_yemen:before{background-position: -276px -211px}
.analytify_south_africa:before{background-position: -101px -166px}
.analytify_zambia:before{background-position: -251px -151px}
.analytify_zimbabwe:before{background-position: -226px -151px}
.analytify_singapore:before{background-position: -276px -241px}
.analytify_serbia:before{background-position: -326px -46px}
.analytify_hong_kong:before{background-position: -26px -241px}
.analytify_taiwan:before{background-position: -301px -211px}
.analytify_mauritius:before{background-position: -51px -181px}
.analytify_bahrain:before{background-position: -151px -136px}
.analytify_palestine:before{background-position: -126px -241px}
.analytify_maldives:before{background-position: -101px -226px}
.analytify_barbados:before{background-position: -1px -256px}
.analytify_malta:before{background-position: -226px -226px}
.analytify_guadeloupe:before{background-position: -51px -241px}
.analytify_macau:before{background-position: -201px -241px}
.analytify_dominica:before{background-position: -276px -31px}
.analytify_st_vincent_grenadines:before{background-position: -326px -16px}
.analytify_curacao:before{background-position: -176px -226px}
.analytify_guam:before{background-position: -51px -226px}
.analytify_jersey:before{background-position: -251px -226px}
.analytify_antigua_barbuda:before{background-position: -101px -46px}
.analytify_french_polynesia:before{background-position: -326px -211px}
.analytify_grenada:before{background-position: -201px -31px}
.analytify_aruba:before{background-position: -1px -226px}
.analytify_congo_drc:before{background-position: -326px -226px}
.analytify_cayman_islands:before{background-position: -26px -256px}
.analytify_guernsey:before{background-position: -151px -241px}
.analytify_u_s_virgin_islands:before{background-position: -151px -226px}
.analytify_seychelles:before{background-position: -176px -166px}
.analytify_cape_verde:before{background-position: -26px -151px}
.analytify_congo_republic:before{background-position: -276px -136px}
.analytify_st_kitts_nevis:before{background-position: -26px -31px}
.analytify_anguilla:before{background-position: -326px -241px}
.analytify_gibraltar:before{background-position: -176px -241px}
.analytify_st_martin,.analytify_sint_maarten:before{background-position: -276px -226px}
.analytify_northern_mariana_islands:before{background-position: -76px -226px}
.analytify_andorra:before{background-position: -251px -241px}
.analytify_british_virgin_islands:before{background-position: -101px -241px}
.analytify_san_marino:before{background-position: -76px -241px}
.analytify_faroe_islands:before{background-position: -301px -241px}
.analytify_kiribati:before{background-position: -201px -16px}
.analytify_turks_caicos_islands:before{background-position: -126px -226px}
.analytify_social_icons::before {
    background-image:url(../../images/social_browser_mobile_icons.png);
    background-repeat:no-repeat;
    background-size: 180px 495px;
    width:34px;
    height:34px;
    position:relative;
    display:inline-block;
    margin-right:6px;
    vertical-align:middle;
	content: '';
}
.analytify_stat_none::before,.analytify_not_set::before {
    background-position:71px 99px;
    background:#444;
    border-radius:999px;
}
.analytify_facebook::before {
    background-position:-73px -99px;
}
.analytify_twitter::before {
    background-position:0 -99px;
}
.analytify_pinterest::before {
    background-position:-37px -99px;
}
.analytify_google_groups::before {
    background-position: -1px -390px;
}
.analytify_linkedin::before {
    background-position:0 -135px;
}
.analytify_wordpress::before {
    background-position: 0 -207px;
}
.analytify_youtube::before {
    background-position:-145px -99px;
}
.analytify_reddit::before {
    background-position: -144px -170px;
}
.analytify_google_plus::before {
    background-position:-109px -99px;
}
.analytify_chrome::before {
    background-position:-74px -310px;
}
.analytify_vkontakte::before{
    background-position:-146px -310px;
}
.analytify_yelp::before {
    background-position: -109px -310px;
}
.analytify_microsoft::before {
    background-position:-37px -43px;
}
.analytify_mac_ios::before,.analytify_macintosh::before,.analytify_apple::before,.analytify_ios::before  {
    background-position:-35px 0px;
}
.analytify_windows::before {
    background-position:-67px 0px;
}
.analytify_edge::before{
    background-position: -1px -344px;
}
.analytify_xiaomi::before{
    background-position: -110px -344px;
}
.analytify_samsung_internet::before{
    background-position: -144px -343px;
}
.analytify_oppo::before{
    background-position: -2px -377px;
    height: 14px;
    width: 42px;
    margin-top: 9px;
    margin-bottom: 9px
}
.analytify_linux::before {
    background-position:0px -43px;
}
.analytify_windows_8::before {
    background-position:-36px -43px;
}
.analytify_chrome_os::before{
    background-position: -74px -366px;
}
.analytify_firefox::before {
    background-position:-106px 0px;
}
.analytify_mozilla::before {
    background-position:-106px 0px;
}
.analytify_android::before {
    background-position:-142px 0px;
}
.analytify_opera::before{
    background-position: -36px -343px;
}
.analytify_safari::before {
    background-position:-104px -42px;
}
.analytify_internet_ex::before {
    background-position:-137px -42px;
}
.analytify_google::before {
    background-position:-72px -42px;
}
.analytify_ipad::before {
    background-position:-70px -42px;
}
.analytify_instagram::before, .analytify_instagram_stories::before {
    background-position:-146px -207px;
}
.analytify_samsung::before {
    background-position:-1px -77px;
    height:19px;
    width:42px;
    margin-top: 7px;
    margin-bottom: 8px;
}
.analytify_wordpress::before {
    background-position: 0 -207px;
}
.analytify_nokia::before {
    background-position: -133px -78px;
    width: 42px;
    height: 21px;
    margin-top: 13px;
}
.analytify_blackberry::before {
    background-position: -34px -274px;
}
.analytify_qmobile::before {
    background-position: 0px -273px;
}
.analytify_htc::before {
    background-position: -145px -241px;
}
.analytify_motorola::before {
    background-position: -109px -241px;
}
.analytify_sony::before {
    background-position: -72px -241px;
}
.analytify_lg::before {
    background-position: -36px -241px;
}
.analytify_huawei::before {
    background-position: 0px -240px;
}
.analytify_tumblr::before{
    background-position: -2px -172px;
}
.analytify_disqus::before{
    background-position: -110px -276px;
}
.analytify_quora::before{
    background-position: -74px -276px;
}
.analytify_ameba::before{
    background-position: -146px -276px;
}
.analytify_vivo::before {
    background-position: -109px -381px;
}
.analytify_infinix::before {
    background-position: -146px -381px;
}
.analytify_oppo::before{
    background-position: -2px -377px;
    height: 14px;
    width: 42px;
    margin-top: 9px;
    margin-bottom: 9px
}
.analytify_lenovo::before {
    background-position: -110px -417px;
}
.analytify_oneplus::before {
    background-position: -1px -428px;
	height: 35px;
}
.analytify_realme::before {
    background-position:-147px -416px;
}
.analytify_walton::before {
    background-position: -74px -400px;
}
.analytify_alcatel::before {
    background-position: -3px -466px;
    /* width: 14px; */
    height: 16px;
    margin-top: 7px;
    margin-bottom: 8px;
}
.tech-stats-wrapper {
  display: flex;
  margin-top: -1px;
  margin-bottom: -1px;
}

.tech-stats-wrapper .table-wrapper {
  width: 33.3333%;
  box-shadow: none;
}

.tech-stats-wrapper .table-wrapper:not(:first-child) {
  border-left: 0;
}

.tech-stats-wrapper th:last-child,
.tech-stats-wrapper td:last-child {
  width: 30%;
  text-align: center;
}

.chrome-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  background: url("../../images/chrome.svg");
  background-size: contain;
}

.window-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  background: url("../../images/window.svg");
  background-size: contain;
}

.android-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  background: url("../../images/android.svg");
  background-size: contain;
}

.browsers-stats {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px;
}

.browsers-stats > * {
  margin: 5px;
}

.total-visits {
  font-size: 13px;
  line-height: 17px;
  color: #444444;
}

.total-visits span {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.keywords-stats {
  margin-top: -10px;
  margin-bottom: -10px;
}

.keywords-stats table {
  width: 100%;
}

.keywords-stats table td {
  padding: 10px 0;
}

.keywords-stats table td:last-child {
  width: 7%;
  text-align: right;
}

.progress-stat .progress-stat-title {
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  margin-bottom: 3px;
  text-align: left;
}

.progress-stat .progress-stat-bar {
  width: 100%;
  height: 3px;
  background-color: #c4c4c4;
  position: relative;
}

.progress-stat .progress-stat-bar > span {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #00c853;
}

.progress-stat .progress-stat-bar.down > span {
  background-color: #ff5252;
}
[data-heading="Engagement Rate"] .progress-stat-bar > span {
  background-color: #ff5252;
}

.social-stats .wordpress-icon {
  display: inline-block;
  padding-left: 34px;
  background: url("../../images/wordpress.svg") center left no-repeat;
  background-size: 20px;
  text-transform: capitalize;
}

.social-stats .linkedin-icon {
  display: inline-block;
  padding-left: 34px;
  background: url("../../images/linkedin.svg") center left no-repeat;
  background-size: 20px;
  text-transform: capitalize;
}

.social-stats .facebook-icon {
  display: inline-block;
  padding-left: 34px;
  background: url("../../images/facebook.svg") center left no-repeat;
  background-size: 20px;
  text-transform: capitalize;
}

.social-stats .instagram-icon {
  display: inline-block;
  padding-left: 34px;
  text-transform: capitalize;
  background: url("../../images/instagram.svg") center left no-repeat;
  background-size: 20px;
}

.social-stats table {
  width: 100%;
  border-collapse: collapse;
}

.social-stats tr td {
  padding: 13px 30px;
}

.social-stats tr td:last-child {
  width: 10%;
  text-align: right;
}

.social-stats tr:not(:last-child) td {
  border-bottom: 1px solid #c4c4c4;
}

.socialRefferers-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.socialRefferers-wrapper .widget {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.socialRefferers-wrapper .widget .widget-footer {
  margin-top: auto;
}

.techStatsLinks-title {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 17px;
}

.techStatsLinks-title > span {
  position: absolute;
  display: block;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  top: 4px;
  left: 0;
}

.techStatsLinks-title p:first-child {
  font-size: 14px;
  line-height: 19px;
  color: #444444;
}

.techStatsLinks-title p:last-child {
  font-size: 12px;
  line-height: 16px;
  color: #448aff;
  font-style: italic;
}

.techStatsLinks-enterancePercentage > *:not(:first-child) {
  margin-top: 3px;
}

@media (max-width: 767px) {
  .graphics-wrapper .map-stats {
    padding: 10px;
  }

  .graphics-wrapper .visitors-table .table-wrapper {
    width: 100%;
  }

  .graphics-wrapper .visitors-table .table-wrapper:not(:first-child) {
    margin-top: -1px;
  }

  .graphics-wrapper .visitors-table th,
  .graphics-wrapper .visitors-table td {
    padding: 10px 20px;
  }

  .tech-stats-wrapper {
    flex-wrap: wrap;
  }

  .tech-stats-wrapper .table-wrapper {
    width: 100%;
  }

  .tech-stats-wrapper .table-wrapper:not(:first-child) {
    margin-top: -1px;
  }

  .socialRefferers-wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
.tabs {
  display: flex;
  margin-bottom: 10px;
}
.period-buttons{
	display: flex;
	gap: 30px;
}
.tabs button {
	display: block;
    width: 100%;
    background: #848484;
    border: 0;
    cursor: pointer;
    color: #fff;
    padding: 8px 5px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
	width: 110px;
	opacity: .3;
}

.tabs button.active {
	opacity: 1;
}

.loading {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.error-message {
  background-color: #ff5252; /* White background */
  border: 1px solid #fff; /* Light grey border */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the box */
  text-align: left; /* Align text to the left */
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  color: #fff; /* Dark grey text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 100%; /* Limit the width */
  margin: 20px auto; /* Center align with spacing */
}

.error-message strong {
  font-weight: bold;
  color: #fff; /* Black color for strong text */
}

.error-message p {
  margin: 10px 0;
  font-size: 14px; /* Font size for paragraphs */
}

.error-message button {
  background-color: #448aff; /* Blue background for button */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners for button */
  padding: 10px 15px; /* Button padding */
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase; /* Uppercase text */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Button shadow */
}

.error-message button:hover {
  background-color: #356fcc; /* Darker blue on hover */
}

.counter-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 5%;
}

.counter-box {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  background-color: #f8f9fa;
  height: 160px;
  align-content: center;
}

.counter-box:last-child {
  border-right: none;
}

.counter-highlight {
  background-color: #ffeb99;
  height: 160px;
  align-content: center;
}

.counter-value {
  font-size: 36px;
  font-weight: bold;
  color: #333;
}

.counter-label {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.refresh-button{
  position: absolute;
  right: 50px;
  top: -14px;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 40px;
  background-color: #fff;
  cursor: pointer;
}
@media (max-width: 767px) {
	.comparison-filter-header{
		flex-direction: column-reverse;
		gap: 10px;
	}
}