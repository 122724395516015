.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.tabs .tabs__list {
  list-style: none;
  display: flex;
  border: 1px solid #e0e0e0;
  border-bottom: none;
}

.tabs .tabs__list > li {
  flex-grow: 1;
  background-color: #f9fafa;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #22282c;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabs .tabs__list > li.active {
  background-color: #ffffff;
  border-color: #ffffff;
}

.tabs .tabs__list > li svg {
  margin-right: 10px;
  font-size: 20px;
}

.tabs .tabs__list > li:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

.tabs .tabs__content {
  border: 1px solid #e0e0e0;
  border-top: none;
  width: 100%;
  padding: 15px 40px 30px;
  background: #fff;
}

.tabs .tabs__content .tabs__item {
  display: none;
  width: 100%; 
  box-sizing: border-box; 
}

.tabs .tabs__content .tabs__item.active {
  display: block;
}

.tabs .tabs-footer {
  border: 1px solid #e0e0e0;
  border-top: 0;
  padding: 15px 20px 15px 60px;
  background: #fafafa url("../../images/info-icon.svg") center left 20px
    no-repeat;
  background-size: 26px;
  font-family: 'Roboto', sans-serif;
  color: #848484;
  font-size: 14px;
  line-height: 1.5;
}

.tabs .tabs-footer span {
  color: #fa5825;
}

@media (max-width: 767px) {
  .tabs .tabs__list > li {
    padding: 10px 15px;
  }

  .tabs .tabs__content {
    padding: 10px 10px 20px;
  }

  .tabs .tabs-footer {
    padding: 15px 20px 15px 40px;
    background-position: center left 14px;
  }
}
