.campaign-box {
  display: flex;
}

.campaign-box:not(:first-child) {
  padding-top: 30px;
  border-top: 1px solid #f2f2f2;
  margin-top: 30px;
}

.campaign-box-topic {
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  margin-right: 30px;
  width: calc(35% - 30px);
}

.campaign-box-topic-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
}

.campaign-box-topic-title .info {
  height: 20px;
  width: 20px;
  background: url("../../images/question-icon.svg") center no-repeat;
  cursor: pointer;
  background-size: contain;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.view-detail-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #4690ca;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
}

.view-detail-btn .tooltip {
  position: absolute;
  background: #000000;
  border-radius: 5px;
  color: #ffffff;
  left: 50%;
  transform: translate(-50%, 10px);
  top: calc(100% + 10px);
  padding: 6px 11px;
  width: 100%;
  text-align: center;
  max-width: max-content;
  text-transform: capitalize;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

.view-detail-btn .tooltip::after {
  content: "";
  height: 0;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -8px;
}

.view-detail-btn:hover .tooltip {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0);
}

.campaign-stats-wrapper {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.campaign-stats-item {
  background: #f2f2f2;
  padding: 10px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.campaign-stats-item .value {
  font-size: 26px;
  line-height: 35px;
}

.campaign-stats-item .label {
  color: #444444;
  font-size: 14px;
  line-height: 19px;
}

.campaigns-table-wrapper {
  margin-top: 30px;
  display: none;
}

.campaigns-table-wrapper .table-wrapper table th,
.campaigns-table-wrapper .table-wrapper table td {
  padding: 20px 8px;
}

.campaigns-table-wrapper .table-wrapper table td:not(:first-child) {
  border-left: 1px solid #e3e6e9;
  border-top: 1px solid #e3e6e9;
}

@media (max-width: 1024px) {
  .campaign-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .campaign-box-topic {
    width: 100%;
    min-height: 270px;
    margin-top: 30px;
  }

  .campaign-stats-wrapper {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .campaign-stats-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .campaign-box-topic {
    margin-top: 20px;
  }
}

@media (max-width: 410px) {
  .campaign-stats-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
