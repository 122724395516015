.widget {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  height: fit-content;
}

.widget .widget-header {
  padding: 19px 20px;
  background-color: #f9fafa;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.widget .widget-header .title {
	color: #444444;
    font-size: 18px;
    font-family: 'Roboto', serif;
    line-height: 1.2;
    margin: 0;
    font-weight: 500;
}

.widget:not(.no-space) .widget-body {
  padding: 20px;
}

.widget .widget-footer {
  padding: 19px 30px 19px 60px;
  background-color: #f9fafa;
  border: 1px solid #e0e0e0;
  background: #f9fafa url("../../images/info-icon.svg") center left 20px
    no-repeat;
  background-size: 26px;
  font-size: 14px;
  line-height: 19px;
  color: #848484;
}

.widget .widget-footer span {
  color: #fa5825;
}

@media (max-width: 767px) {
  .widget:not(.no-space) .widget-body {
    padding: 20px;
  }

  .widget .widget-header {
    padding: 20px;
  }

  .widget .widget-header .title {
    font-size: 15px;
  }

  .widget .widget-footer {
    padding: 15px 20px 15px 50px;
    background-position: center left 15px;
  }
}
