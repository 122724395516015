.footer {
  margin-top: auto;
  background-color: #1f1f1f;
}

.footer .wpb-container {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
}

.footer .copyright {
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
}
