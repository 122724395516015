.drawer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 98;
  margin: 0 !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}

.drawer .drawer-overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.74);
}

.drawer .drawer-content{
  position: absolute;
  width: 350px;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  transform: translateX(350px);
  transition: 0.3s all;
  padding: 20px;
}

.drawer.active{
  opacity: 1;
  visibility: visible;
}

.drawer.active .drawer-content{
  transform: translateX(0);
}