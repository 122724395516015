.header-top {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 3;
}

.header-top .wpb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-top .brand-logo {
  height: 33px;
  max-width: 300px;
  margin-right: 30px;
}

.header-top .brand-logo img {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.header-top .menu-btn {
  height: 17px;
  width: 21px;
  display: block;
  border: none;
  background: url("../../images/humberger.svg") center no-repeat;
  background-size: contain;
  cursor: pointer;
  display: none;
}

.header-top .user-profile {
  color: #000000;
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.header-top .user-profile.loading:after{
  content: "";
  background: #ffffff6b url("../../images/loader.svg") center left no-repeat;
  background-size: 20px;
  position: absolute;
  height: 100%;
  width: calc(100% + 25px);
  right: 0;
  top: 0;
}

.user-dropdown{
  position: absolute;
  top: calc(100% + 25px);
  right: 0;
  background: #ffffff;
  z-index: 2;
  list-style: none;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 140px;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: 0.3s all;
}

.user-profile:hover .user-dropdown{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.user-dropdown:after{
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  height: 25px;
}

.user-dropdown:before{
  content: "";
  height: 0;
  width: 0;
  border-left: 10px solid rgba(128,128,128,0);
  border-bottom: 10px solid rgb(255,255,255);
  border-right: 10px solid rgba(128,128,128,0);
  top: -10px;
  right: 20px;
  position: absolute;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.05));
}

.user-dropdown a{
  color: #000000;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  text-decoration: none;
  margin: 5px 10px;
  border-radius: 5px;
  transition: 0.3s all;
}

.user-dropdown a:hover{
  background: #eaeaea;
}

.user-dropdown a svg{
  margin-right: 10px;
  font-size: 20px;
  margin-top: 2px
}

.header-top .user-profile > img{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  min-width: 30px;
}

.header-top .user-profile .user-name{
  margin-left: 5px;
}

.header-top .user-icon {
  background: url("../../images/user-icon.svg") center no-repeat;
  background-size: contain;
  width: 17px;
  height: 19px;
  display: block;
}

.header-bottom {
  position: relative;
}

.header-bottom .wpb-container {
  position: relative;
  z-index: 2;
  min-height: 210px;
  display: flex;
  align-items: flex-end;
}

.header-bottom::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 190px;
  background: url("../../images/graph-green.png") repeat-x 0 bottom;
  animation: graph_animation 300s linear infinite;
  z-index: 1;
}

.header-bottom::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 190px;
  background: url("../../images/graph-blue.png") repeat-x 0 bottom;
  animation: graph_animation 150s linear infinite;
}

@keyframes graph_animation {
  0% {
    background-position: 0 bottom;
  }

  100% {
    background-position: -10000px bottom;
  }
}

.header .main-menu {
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #cccccc;
}

.header .main-menu > li {
  flex: 1;
  position: relative;
  max-width: 20%;
}

.header .main-menu > li.hide{
  display: none !important;
}

.header .main-menu > li > .sub-menu {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 3px 0 14px 0;
  list-style: none;
  top: 100%;
  transition: 0.3s all;
  min-width: 150px;
  transform: perspective(999px) rotateX(-20deg);
  transform-origin: top;
  opacity: 0;
  visibility: hidden;
}

.header .main-menu > li:hover > .sub-menu {
  transform: perspective(999px) rotateX(0);
  opacity: 1;
  visibility: visible;
  display: block;
}

.header .main-menu > li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #555555;
  text-transform: capitalize;
  text-decoration: none;
  position: relative;
  text-align: center;
  transition: 0.3s all;
}

.header .main-menu > li:hover > a {
  background-color: #00c853;
  color: #ffffff;
}
.header .main-menu > li > .sub-menu{
	box-shadow: 0 5px 10px rgba(0, 0, 0, .10);
    z-index: 10;
}
.header .main-menu > li > .sub-menu a {
  padding: 10px 20px;
  text-align: left;
  align-items: flex-start;
}

.header .main-menu > li > .sub-menu a::after {
  content: "";
  height: 40%;
  width: 0;
  background-color: #00c853;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 0 50% 50% 0;
  position: absolute;
  transition: 0.3s all;
}

.header .main-menu > li > .sub-menu li.active > a::after {
  width: 5px;
}

.header .main-menu > li > .sub-menu a:is(:hover, .active) {
  background-color: #eee;
}

.header .main-menu > li:not(:last-child) > a:after {
  position: absolute;
  content: "";
  height: 42px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background-color: #dddddd;
}

.header .main-menu > li > a::before {
  position: absolute;
  content: "";
  height: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #00c853;
  transition: 0.3s all;
}

.header .main-menu > li.active > a::before {
  height: 4px;
}

.header .main-menu > li a span:last-child {
  font-size: 10px;
  line-height: 13px;
  color: #b7b7b7;
  margin-top: 2px;
}

.header .main-menu > li:hover > a span {
  color: #ffffff;
}

@media (min-width: 768px) {
  .header .main-menu {
    display: flex !important;
  }

  .header .main-menu > li > .sub-menu {
    display: block !important;
  }
}

@media (max-width: 767px) {
	.header-bottom {
		position: relative;
		height: 100px;
		padding-top: 60px;
	}
	.main-content > .wpb-container{
		padding-top: 410px;
	}
  .header-top .user-profile {
    margin-left: 20px;
    padding-left: 18px;
  }

  .header-top .user-profile .user-name {
    display: none;
  }

  .header-top .menu-btn {
    display: block;
    margin-right: 20px;
  }

  .header-top .brand-logo {
    margin: 0;
    height: 23px;
  }

  .header-top .wpb-container {
    min-height: 62px;
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .header-bottom .wpb-container {
    min-height: 75px;
  }

  .header-bottom::before {
    background-image: url("../../images/graph-blue-mobile.png");
    animation-duration: 100px;
    height: 69px;
  }

  .header-bottom::after {
    background-image: url("../../images/graph-green-mobile.png");
    animation-duration: 200px;
    height: 69px;
  }

  .header .main-menu {
    flex-direction: column;
	text-align: center;
  }

  .header .main-menu > li {
    width: 100%;
	max-width: 100%;
  }

  .header .main-menu > li a {
    align-items: center;
    text-align: center;
	padding-left: 8px;
	padding-right: 8px;
    border-bottom: 1px solid #dddddd;
  }

  .header .main-menu > li:not(:last-child) > a::after,
  .header .main-menu > li > a::before {
    content: none;
  }

  .header .main-menu > li.active > a {
    border-color: #00c853;
	border-bottom-width: 4px;
  }

  .header .main-menu > li > .sub-menu {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
    display: none;
    transition: none;
	background: #fff;
	z-index: 10;
	text-align: center;
	padding: 0;

}

  .header .main-menu > li > .sub-menu a {
	text-align: left;
	align-items: start;
    padding-left: 20px;
    padding-right: 20px;
	border-bottom: 0;
  }


  .header .main-menu > li > .sub-menu a::after {
    content: none;
  }

  .header .main-menu > li > .sub-menu li.active > a {
    border-color: #00c853;
  }
}
