.general-stats-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.stat-box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  min-height: 230px;
}

.stat-box .stat-box-header {
  padding: 25px 20px 11px 20px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.stat-box .stat-box-header:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 62px;
  background: #00c853;
  left: 50%;
  transform: translatex(-50%);
}

.stat-box .stat-box-body {
  padding: 25px 20px 20px 20px;
  text-align: center;
}

.stat-box .stat-box-body .stat-box-value {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-transform: lowercase;
  color: #444444;
}
.stat-box .stat-box-body .stat-box-value small {
  font-size: 22px;
  line-height: 28px;
}

.stat-box .stat-box-body .stat-box-desc {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #8f919d;
}

.stat-box .stat-box-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  background: #fafafa;
  padding: 7px 20px;
  border-top: 1px solid #e0e0e0;
  margin-top: auto;
}

.stat-box .stat-box-footer span {
  color: #fa5825;
}

.stat-box .stat-box-footer .stat-box-response {
	font-size: 16px;
    font-weight: 500;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    margin-left: 30px;
}

.stat-box .stat-box-footer .stat-box-response.up {
  color: #00c853;
  background: url("../../images/up-icon.svg") center left no-repeat;
  background-size: 10px;
  padding-left: 15px;
}

.stat-box .stat-box-footer .stat-box-response.down {
  color: #fa5825;
  background: url("../../images/down-icon.svg") center left no-repeat;
  background-size: 10px;
  padding-left: 15px;
}

.stat-box .stat-box-footer .stat-box-response-text {
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #8f919d;
  padding: 5px;
}

@media (max-width: 880px) {
  .general-stats-wrapper {
    grid-template-columns: repeat(2, calc(50% - 15px));
  }
}

@media (max-width: 567px) {
  .general-stats-wrapper {
    grid-template-columns: repeat(1, 100%);
  }
}
