@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Semibold.woff2") format("woff2"),
    url("../fonts/SegoeUI-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI.woff2") format("woff2"),
    url("../fonts/SegoeUI.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Semilight.woff2") format("woff2"),
    url("../fonts/SegoeUI-Semilight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Bold.woff2") format("woff2"),
    url("../fonts/SegoeUI-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Italic.woff2") format("woff2"),
    url("../fonts/SegoeUI-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img,
svg {
  max-width: 100%;
}

body {
  font-family: "Segoe UI", sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #22282c;
  font-weight: 400;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wpb-container {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.main-content{
  flex: 1;
  position: relative;
}

.main-content > .wpb-container {
  padding-bottom: 90px;
  padding-top: 10px;
  max-width: 1130px;
    padding-inline: 30px;
	background-color: #F0F5FA;
}

.main-content > .wpb-container > * {
  margin-top: 30px;
}

body a {
  color: #2271b1;
  text-decoration: underline;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
}

body a:hover {
  text-decoration: none;
}

body .form-group:not(:last-child) {
  margin-bottom: 20px;
}

body form .form-label {
  font-family: "Segoe UI";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #22282c;
}

body form .form-label + * {
  margin-top: 10px;
}

body .form-input {
  background: #ffffff;
  border: 1px solid #dedede;
  height: 54px;
  width: 100%;
  padding: 15px 20px;
  font-family: "Segoe UI";
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: #22282c;
  outline: none;
}

body .form-input::placeholder {
  color: #cacaca;
}

body .form-input:focus {
  border: 1px solid #22282c;
}

body .form-group .error-msg {
  display: none;
  color: #e53029;
  margin-top: 5px;
  font-size: 14px;
}

body .form-group.error .error-msg {
  display: block;
}

body .form-group.error input,
body .form-group.error select {
  border-color: #e53029;
}

body .form-group.error label {
  color: #e53029;
}

body .form-checkbox {
  position: relative;
  padding-left: 28px;
}

body .form-checkbox input[type="checkbox"] {
  position: absolute;
  top: 3px;
  left: 0;
  appearance: none;
  height: 18px;
  width: 18px;
  background: #ffffff center no-repeat;
  background-size: 10px;
  border: 1px solid #dedede;
}

body .form-checkbox input[type="checkbox"]:checked {
  background-image: url("../images/checkmark-icon.svg");
}

body .form-checkbox label {
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  color: #22282c;
}

body .btn {
  text-decoration: none;
  display: inline-block;
  background: #448aff;
  font-family: "Segoe UI";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  padding: 17px;
  border: none;
  outline: none;
  transition: 0.3s all;
  cursor: pointer;
  text-align: center;
  position: relative;
}

body .btn:disabled{
  opacity: 0.6;
}

body .btn:disabled::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: url("../images/loader.svg") center no-repeat;
  left: 0;
  top: 0;
  background-size: 30px;
}

body .btn:hover {
  background: darken($color: #448aff, $amount: 10%);
}

body .google-btn {
  background-color: #ececec;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  color: #707070;
  padding-left: 54px;
}

body .google-btn::before {
  content: "";
  display: block;
  height: 26px;
  width: 26px;
  background: url("../images/google-icon.svg") center no-repeat;
  background-size: contain;
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
}

body .google-btn:hover {
  background: darken($color: #ececec, $amount: 10%);
}

.justify-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 10;
}

.loader img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  z-index: 2;
}

.loader .loading-img{
  display: flex;
  position: relative;
  background-color: #ffffff;
}

select{
  background: #ffffff;
  border: 1px solid #dedede;
  height: 54px;
  width: 100%;
  padding: 15px 20px;
  font-family: "Segoe UI";
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: #22282c;
  outline: none;
}

button[type="submit"]{
  width: 100%;
}

button.disable{
  opacity: 0.6;
  pointer-events: none;
}
.stat-box-chart{
	margin-top: -40px;
}
@media (max-width: 767px) {
  .main-content > .wpb-container > * {
    margin-top: 20px;
  }

  .main-content > .wpb-container > *:first-child {
    margin-top: 0;
  }

  .main-content > .wpb-container {
    padding-top: 20px;
    padding-bottom: 40px;
	padding-inline: 15px;
  }

  .wpb-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .loader > * {
    height: 60px;
    width: 60px;
  }
}
