.table-wrapper {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  background: #fff;
}

.table-wrapper .table-header {
  background: #f9fafa;
  border-bottom: 1px solid #e0e0e0;
  padding: 19px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #444444;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
}

.table-wrapper .table-header a {
  display: flex;
  margin-left: 5px;
  transition: 0.3s all;
}

.user-profile img{
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
}

.action-btns{
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btns > *{
  background: none;
  border: none;
  font-size: 20px;
  margin: 5px;
  cursor: pointer;
  transition: 0.3s all;
}

.action-btns .edit-btn{
  color: rgb(15,144,219);
  min-width: 20px;
}

.action-btns .delete-btn{
  color: red;
  min-width: 20px;
}

.table-wrapper .table-header a:hover {
  opacity: 0.7;
}

.checkmarkIcon {
  color: rgb(19, 190, 90);
  font-size: 20px;
}

.closeIcon{
  font-size: 20px;
  color: red;
}

.table-wrapper .table-footer {
  padding: 15px 16px 15px 20px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafa;
  min-height: 60px;
}

.table-loading{
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.table-loading::after{
  content: "";
  height: 40px;
  width: 40px;
  display: block;
  background: url("../../images/analytify-icon.svg") center no-repeat;
  background-size: contain;
  animation: rotate 1s linear infinite ;
}

@keyframes rotate {
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(-180deg); }
}

.table-wrapper .table-footer .info {
  background: url("../../images/info-icon.svg") center left no-repeat;
  background-size: 26px;
  padding-left: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 26px;
  color: #848484;
}

.table-wrapper .table-footer .info span {
  color: #fa5825;
}

.table-wrapper table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

.table-wrapper table th {
  background-color: #f5f9ff;
  padding: 20px;
  border-bottom: 1px solid #e3e6e9;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  color: #444444;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  text-transform: capitalize;
}

.table-wrapper table th:not(:last-child) {
  border-right: 1px solid #e3e6e9;
}

.table-wrapper table td {
  text-align: left;
  padding: 16px 20px;
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  font-family: 'Roboto', sans-serif;
  word-break: break-word;
}
.table-wrapper table td:first-child{
	word-break: normal;
}
.table-wrapper table td a {
  color: #2271b1;
}

.table-wrapper table tr:not(:last-child) td {
  border-bottom: 1px solid #e3e6e9;
}

.table-wrapper .table-pagination {
  display: flex;
  list-style: none;
  margin: -2.5px;
}

.table-wrapper .table-pagination > li {
  background: #ffffff;
  border: 1px solid #e2e5e8;
  padding: 5px;
  height: 30px;
  min-width: 30px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  transition: 0.3s all;
  cursor: pointer;
  margin: 2.5px;
}

.table-wrapper .table-pagination > li:is(.active, :hover) {
  background: #448aff;
  color: #ffffff;
}

.table-responsive {
  overflow: auto;
}

.table-responsive table {
  min-width: 600px;
}

.table-data-not-found {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #6c6c6c;
  min-height: 198px;
}

.table-data-not-found > span::before {
  content: "";
  vertical-align: middle;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: inline-block;
  background: url("../../images/not-found-icon.svg") center no-repeat;
  background-size: contain;
  position: relative;
  top: -2px;
}

.dimensions-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.dimensions-stats table {
  width: 100%;
  border-collapse: collapse;
}

.dimensions-stats table tr:not(:last-child) td {
  border-bottom: 1px solid #e3e6e9;
}

.dimensions-stats table td:last-child {
  text-align: right;
}

.dimensions-stats table td {
  padding: 16px 27px;
}

@media (max-width: 767px) {
  .table-wrapper table th,
  .table-wrapper table td {
    padding: 10px;
  }

  .dimensions-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .table-wrapper tr td[data-heading] {
		display: block;
		text-align: left !important;
		padding-left: 160px !important;
		position: relative;
		width: 100% !important;
		word-break: break-word;
		font-size: 13px;
	}
	.table-wrapper thead{
		display: none;
	}
	.table-wrapper tr td[data-heading]:before {
        content: attr(data-heading);
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        height: 100%;
        color: #444;
        background: #f5f9ff;
        border-right: 1px solid #e2e5e8;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        box-sizing: border-box;
    }
	.table-responsive table{
		min-width: 100%;
	}
}

.key-events-wrapper table tr:last-child td {
  font-weight: bold;
}